:root {
  --main-color: #D47A0C;
  --main-color-gradient: linear-gradient(0deg, #D47A0C, #FD8B00);
  --primary-color: #071A2B;
  --primary-color-gradient: linear-gradient(0deg, #071A2B, #185891);
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Antonio:wght@100..700&display=swap');

a {
  text-decoration: none;
  color: inherit;
}



.form-container {
  display: none; /* Initially hidden */
  transition: all 0.3s ease; /* Smooth transition */
}

.form-container.active {
  display: block; /* Show when active */
}









a:hover {
  color: inherit;
}

.header {
  background: var(--main-color-gradient);
}

.header_list ul {
  list-style: none;
  padding: 0;
}

marquee p {
  display: initial;
  margin: 0 5px;
}

.header_list ul li img {
  width: 37px;
  margin-right: 12px;
}

ul.navbar-nav li .dropdown-menu li {
  margin: 0;
  font-size: 14px;
}

ul.navbar-nav li .dropdown-menu li a {
  font-family: "Inter", sans-serif;
}

.innersec h1,
.innersec h2,
.innersec h3,
.innersec h4,
.innersec h5,
.innersec h6,
.innersec p {
  font-family: "Inter", sans-serif;
}

.header_list ul li {
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  font-size: 17px;
}

.header_list ul li a {
  text-decoration: none;
  color: #fff;
}

.header_list {
  color: #fff;
  width: 83%;
}

.button-flex {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}

.button-flex button.active {
  background: var(--primary-color);
  color: #fff;
  font-weight: 400;
  box-shadow: inset 2px 3px 9px 1px #00000061, inset -3px -3px 8px 1px #ffffff38;
  padding: 12px;
  border: 0px;
}

.button-flex button {
  margin-left: 20px;
  padding: 10px;
  min-width: 150px;
  border: 0px;
  background: transparent;
  border: 2px solid #071a2b;
  border-radius: 5px;
  font-weight: 600;
  font-size: 18px;
}

.highlights {
  display: flex;
  align-items: center;
  background: #071a2b;
}

.highlights a {
  text-decoration: none;
  color: inherit;
}

.highlights p {
  margin-bottom: 0;
  color: #fff;
}

.header-nav:before {
  content: "";
  width: 100%;
  height: 43px;
  background: var(--primary-color-gradient);
  position: absolute;
  left: 0;
}

ul.navbar-nav li {
  margin: 0px 10px;
}

nav.navbar.navbar-expand-lg {
  width: 100%;
  padding: 0;
  font-weight: 800;
  font-family: "Antonio", sans-serif;
}

li.nav-item.public_dis {
  position: absolute;
  right: 0;
  background: #e78105;
  font-weight: 600;
}

.navbar-expand-lg .navbar-collapse {
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.navbar-expand-lg .navbar-nav .nav-link {
  color: #fff !important;
  text-transform: uppercase;
  padding: 10px 10px;
  letter-spacing: 1px;
  font-size: 15px;
}

.navbar-expand-lg .navbar-nav .public_dis a {
  color: #000 !important;
  font-weight: 800;
}

.homeslider {
  position: relative;
}

.slider-text {
  position: absolute;
  bottom: 80px;
  left: 160px;
  width: 600px;
  max-width: 80%;
  color: #fff;
  border: 1px solid #fb8e0b;
  background: #e5810882;
  padding: 27px;
  font-weight: 800;
  font-family: "Antonio", sans-serif;
  border-radius: 22px;
}

.slider-text h1 {
  font-size: 3.3rem;
  font-weight: 700;
}

.slider-text span {
  color: #333;
}


.four-blk img {
  width: 77px;
  margin: auto;
  display: block;
}

.four-blk {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff0dc;
  padding: 20px;
  border-radius: 21px;
}

.four-blk-icon {
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed var(--main-color);
  margin-bottom: 30px;
  border-radius: 50%;
  position: relative;
  transition: 0.5s;
}

.four-blk>div {
  margin: 40px 40px 25px 40px;
  text-align: center;
}

.four-blk-icon:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border: 5px solid var(--primary-color);
  transform: scale(1.2) rotate(45deg);
  border-top: 5px solid #0000;
  border-right: 5px solid #0000;
  border-bottom: 5px solid #0000;
  border-radius: 50%;
  transition: 0.5s;
}

.four-blk-icon:hover:before {
  border-top: 5px solid var(--primary-color);
  border-right: 5px solid var(--primary-color);
  border-bottom: 5px solid var(--primary-color);
  transform: scale(1.2) rotate(0deg);
}


.aboutsec .title {
  text-align: center;
  text-transform: uppercase;
}

.aboutsec .title h2 {
  font-size: 3.5rem;
  font-weight: 700;
  color: #071A2B;
}

.aboutsec .title p {
  font-weight: 700;
  font-size: 19px;
}

.aboutdesc .button button {
  font-size: 20px;
  padding: 9px 20px;
  margin: 10px;
  border-radius: 5px;
  background: var(--primary-color);
  border: 0px;
  min-width: 170px;
  color: #fff;
  font-weight: 600;
  box-shadow: inset 2px 3px 12px 0px #0000004d, inset -3px -3px 7px 0px #ffffff8c;
}

.aboutdesc .button button.active {
  background: var(--main-color-gradient);
  color: #000;

}

.aboutdesc .button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 20px;
}

.aboutsecscddesc {
  text-align: center;
  padding: 100px 0 0;
}

.maintitle h2 {
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 700;
  color: #071A2B;
}

.padding-lr {
  width: 90%;
  margin: auto
}

.homeeventbox {
  width: 88%;
  margin: auto;
  background: #FFF2A3;
  padding: 7px;
  border-radius: 33px;
  position: relative;
  box-shadow: 1px 30px 13px -30px #00000059;
  margin-bottom: 30px;
}

.homeeventbox.news-blk .homeeventboxbottom span {
  padding: 1px 7px 7px;
  vertical-align: middle;
  /* font-size: 28px; */
}

.homeeventbox .homeeventboxdesc h5 {
  text-transform: uppercase;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.homeeventbox img {
  width: 100%;
  border-radius: 33px;
  height: 210px;
  object-fit: contain;
  background: #fff5ba;
}

.homeeventbox .homeeventboxdesc {
  padding: 20px 10px;
  text-align: center;
}

.homeeventbox .homeeventboxdate {
  position: absolute;
  bottom: 0;
  transform: translate(-50%, 32%);
  left: 50%;
  background: var(--primary-color);
  color: #fff;
  padding: 5px 10px;
  border-radius: 8px;
  text-transform: uppercase;
}

.homeeventboximg {
  position: relative;
}

.homeeventboxbottom span {
  position: absolute;
  top: 0;
  font-size: 13px;
}

.homeeventboxbottom span:nth-child(1) {
  left: 20px;
  top: 20px;
  background: #FFF2A3;
  padding: 0px 8px 2px;
  border-radius: 5px;
}

.homeeventboxbottom span:nth-child(2) {
  right: 20px;
  top: 20px;
  background: #FFF2A3;
  padding: 2px 8px;
  border-radius: 5px;
}

.news-blk .homeeventboxbottom span i {
  font-size: 22px;
}

.homeeventboxbottom span i {
  margin-right: 4px;
  vertical-align: bottom;
}

.homeeventbox .homeeventboxdesc p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  margin-bottom: 0;
}

.news-blk .attachment {
  position: absolute;
  top: 0;
  left: 0;
}

.news-blk img {
  height: 150px;
}

.homeeventbox.news-blk .homeeventboxdesc h5 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  min-height: 77px;
}

.messagebox {
  background: url(https://webapi.entab.info/api/image/JRPSB/public/Images/message-bg.png);
  background-position: left top;
  background-size: 100% 100%;
  text-align: center;
  padding: 0px 0px 40px;
  border-radius: 50px 50px 0 0;
  position: relative;
}

.messagebox img.img-fluid.top-quote {
  position: absolute;
  left: 40px;
  top: 20px;
}

.messagedesc {
  position: relative;
  color: #fff;
  padding: 90px 90px 100px;
}

.messagebox img.img-fluid.bottom-quote {
  transform: rotate(180deg);
  right: 30px;
  bottom: 50px;
  position: absolute;
}

.principalimgbox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 50px;
  padding-right: 35px;
}

.principalimgbox p {
  color: #000;
  font-weight: 600;
  text-align: right;
  font-style: italic;
  padding-right: 20px;
}

.principalimgbox p span {
  color: #333;
  display: block;
}

.topperdesc h5 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.topperimages {
  background: url(https://webapi.entab.info/api/image/JRPSB/public/Images/topper-bg.png);
  background-size: 100% 100%;
  width: 160px;
  height: 160px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 27px;
  margin-bottom: 20px;
  position: relative;
}

.topperimages img {
  border-radius: 50%;
  height: 110px;
  width: 100%;
}

.topperdesc {
  text-align: center;
}

.topperdesc p {
  text-transform: uppercase;
  font-style: italic;
  margin: auto;
  color: #333;
}

.birthday-bg .topperimages {
  background: url(https://webapi.entab.info/api/image/JRPSB/public/Images/birthday_bg.png);
  background-size: 100% 100%;
  height: 140px;
  z-index: 9;
  position: relative;
}

.gallerysecimg img {
  width: 100%;
  height: 240px;
  object-fit: contain;
  object-position: center;
  background: #f1f1f1;
}

.gallerysec {
  width: 90%;
  margin: auto;
  border: 9px solid #fff;
  border-radius: 15px;
  box-shadow: 0px 20px 10px -15px #ddd;
  margin-bottom: 30px;
  position: relative;
}

.gallerysecimg {
  position: relative;
}

.gallerysecimg h3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  background: var(--main-color);
  width: 100%;
  text-align: center;
  padding: 5px;
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 400;
  opacity: 0;
  transition: 0.5s;
}

.gallerysec button {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background: var(--primary-color-gradient);
  color: #fff;
  border-radius: 8px;
  letter-spacing: 1px;
  padding: 6px 10px;
  border: 0px;
  opacity: 0;
  transition: 0.5s;
}

.gallerysec:hover .gallerysecimg h3,
.gallerysec:hover button {
  opacity: 1;
}

.toopersecbg .stripClass {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0%, 0%);
  min-width: 150px;
}

.toopersecbg {
  position: relative;
}

.toopersecbg {
  position: relative;
}

.toopersecbg .stripClass .classtopper.active {
  background: url(https://webapi.entab.info/api/image/JRPSB/public/Images/active-topper.png);
  background-size: 100% 100%;
}

.toopersecbg .stripClass .classtopper {
  background: url(https://webapi.entab.info/api/image/JRPSB/public/Images/topperclass.png);
  margin: 11px 0;
  text-align: center;
  padding: 20px;
  background-size: 100% 100%;
  font-weight: 600;
}

.footer img {
  margin: auto;
  display: block;
}

.footer img {
  margin: auto;
  display: block;
}

.footer img {
  margin: auto;
  display: block;
}

.quick-link ul {
  display: row;
  flex-direction: column;
  height: 130px;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
}

.footer {
  background: linear-gradient(90deg, #d47a0c 97%, #d47a0c 3%);
  padding: 40px 0px 20px 0px;
}

.footer a {
  color: #000;
  text-decoration: none;
  display: flex;
  padding: 5px;
}
.plus {
  float: right;
  color: #ffffff;
  background: #f68802;
  height: 30px;
  line-height: 12px;
  padding: 10px;
}
.map {
  width: 75%;
  display: inline;
}

.d-flex.mb-app {
  /* width: 50%; */
  display: inline-flex !important;
}

.map iframe {
  width: 100%;
}

.map iframe {
  width: 90%;
  border: 6px solid #d7d7d7;
}

.quick-link ul li {
  margin-top: 6px;
}

.quick-link h5,
.cont-dtl h5 {
  text-transform: uppercase;
  font-weight: 700;
}

.footer a i {
  font-size: 12px;
  margin-right: 5px;
}

.cont-dtl i {
  font-size: 17px !important;
  margin-right: 5px;
}

img.img-fluid.phone-img {
  min-width: 150px;
  float: right;
  margin-top: -70px;
}

.copyright {
  text-align: center;
  padding: 5px;
  background: var(--primary-color);
  color: #fff;
}

.contact {
  display: flex;
}

.copyright p {
  margin-bottom: 0;
  font-size: 13px;
}

.button-ftr {
  display: flex;
}

.phone-img {
  width: 200px;
}

.mobile-app p:last-child {
  margin-bottom: 0;
  background: #ffb358;
  padding: 2px 7px;
  border-radius: 5px;
  width: fit-content;
  margin-top: 8px;
  font-size: 14px;
}

.main-background {
  background: url(https://webapi.entab.info/api/image/JRPSB/public/Images/main-bg.png);
}

.button-ftr img {
  width: 80px;
}

.homegallery,
.homemsg,
.aboutsec {
  padding: 70px 0;
}

.toopersec {
  padding-top: 50px;
}

.social_media ul {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-bottom: 0;
}

img.img-fluid.ftr-logo {
  width: 230px;
  max-width: 100%;
}

.social_media ul li {
  margin: 8px;
}

.topperimages p {
  background: url(https://webapi.entab.info/api/image/JRPSB/public/Images/marks.png);
  background-size: 100% 100%;
  padding: 14px 0;
  position: absolute;
  bottom: -40px;
  width: 100%;
  opacity: 0;
  transition: 1s;
  color: yellow;
  font-weight: 700;
  font-size: 19px;
}

.birthday-bg {
  position: relative;
}

.birthday-bg .topperimages p {
  background: url(https://webapi.entab.info/api/image/JRPSB/public/Images/birthday-strip.png);
  background-size: 100% 100%;
  padding: 6px 0;
  position: absolute;
  bottom: -40px;
  width: 100%;
  opacity: 0;
  transition: 1s;
  color: yellow;
  font-weight: 700;
  font-size: 16px;
}

.birthday-bg:before {
  background: url(https://webapi.entab.info/api/image/JRPSB/public/Images/baloons.png);
  background-repeat: no-repeat;
  background-size: 100%;
  content: "";
  width: 39%;
  height: 100%;
  position: absolute;
  left: 40%;
  top: 0;
  transform: rotate(314deg) scale(0.5);
  transition: 0.5s;
}

.birthday-bg:after {
  background: url(https://webapi.entab.info/api/image/JRPSB/public/Images/baloons.png);
  background-repeat: no-repeat;
  background-size: 100%;
  content: "";
  width: 39%;
  height: 100%;
  position: absolute;
  right: 40%;
  top: 0;
  transform: rotate(6deg) scale(0.5);
  transition: 0.5s;

}

.birthday-bg:hover:before {
  left: 20px;
  top: -20%;
  transform: rotate(336deg) scale(1);
}

.birthday-bg:hover:after {
  right: 6px;
  top: -18%;
  transform: rotate(6deg) scale(1);

}

.aboutmidsec button {
  width: max-content;
  padding: 8px 20px;
  margin: auto;
  display: block;
  margin-top: 20px;
  border-radius: 5px;
  border: 2px solid #071A2B;
  color: #071A2B;
  background: transparent;
  font-weight: 600;
  font-size: 16px;
}

.topperdiv {
  margin-top: 40px;
}

.topperdiv:hover .topperimages p {
  opacity: 1;
  bottom: -20px;
}

.innerslide {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: linear-gradient(360deg, #1c1b3b8f 100%, #1c1b3b91 100%, #3333338c 100%), url('https://webapi.entab.info/api/image/JRPSB/public/Images/innersld.jpg') no-repeat center center;
  background-size: cover;
  height: 255px;
  padding-bottom: 50px;
}

.innersec {
  min-height: 400px;
  padding: 60px 10px 80px 10px;
}

.innersec p {
  line-height: 34px;
  font-size: 16px;
  color: #4e4e4e;
}

.innersec h1 {
  z-index: -1;
  font-size: 35px;
  position: relative;
  padding-left: 70px;
  background: -webkit-linear-gradient(#f78801, #134877);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 45px;
  padding-bottom: 20px;
}

.innersec h1:after {
  position: absolute;
  content: ' ';
  width: 60px;
  height: 3px;
  background: -webkit-linear-gradient(#f78801, #134877);
  top: 20px;
  bottom: 0;
  left: 0;
}

.breadcrumb li:before {
  position: absolute;
  content: ' \F138 ';
  width: 100%;
  height: 100%;
  font-family: bootstrap-icons;
  left: -15px;
}

.breadcrumb li:first-child:before {
  content: "\f404";
  background: #c9b558;
  width: 30px;
  border-radius: 20px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -25px;
  top: -3px;
}

.breadcrumb li a {
  color: #f9f9f9;
  position: relative;
}

.breadcrumb li {
  padding: 0px 15px 0px 15px;
  color: #fff;
  position: relative;
  margin-left: 7px;
  font-size: 14px;
}

ul.breadcrumb {
  margin-bottom: 0;
}

#myImg,
#myImg1,
#myImg2 {
  width: 100%;
  border: 5px solid rgba(158, 158, 158, 0.1);
  background: #eaeaea;
  cursor: pointer;
  transition: 0.3s;
}

.erp_sec img {
  display: inline-block;
}

.erp_sec h4.imgheading {
  text-align: center;
}

.assistance {
  background: #eaeeff;
  padding: 20px;
  margin-top: 30px;
}

.erp_sec h4 {
  text-align: center;
  line-height: 40px;
  font-size: 17px;
  margin-bottom: 0;
  margin-top: 15px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
}

.assistance {
  background: rgb(230 243 255);
  padding: 13px;
  border: solid 1px #ddd;
  margin-top: 20px;
}

.erpbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.erpboxdesc {
  width: 72%;
}

.erpboximg {
  width: 26%;
}

.erpboxdescpopup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.erppopup {
  width: 30%;
  margin: 10px;
}

.imgheading a {
  text-align: center;
  width: 100%;
  display: block;
  font-size: 20px;
  padding: 10px 0;
}

.erppopup .modal-body h5 {
  color: #000;
  text-align: center;
  padding-top: 20px;
}

.chooseyear {
  width: max-content;
  float: right;
  margin-bottom: 30px;
  margin-top: -45px;
}

#chooseddlYear option {
  color: black;
}

.chooseyear select {
  margin: 0px;
}

.chooseyear select {
  width: 100%;
  background: unset;
  border: unset;
  height: 28px;
  margin-top: 15px;
  border: unset;
  border-bottom: 1px solid grey;
}

.clr {
  clear: both
}

.video-gallery {
  padding: 20px;
  box-shadow: 0px 1px 4px 1px #03030330;
  margin-bottom: 20px;
  background: #f1f1f1;
  border-radius: 3px;
}

.video-gallery a {
  display: flex;
  color: inherit;
  align-items: center;
}

.video-gallery a i {
  font-size: 40px;
  color: #ad0807;
  margin-right: 10px;
  display: flex;
}

.video-gallery a h3 {
  font-size: 16px;
  color: #333;
  margin-bottom: 0;
  line-height: 24px;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: 0;
  padding-left: 0;
}

.video-gallery p {
  font-size: 12px;
  margin-bottom: 0;
}

.video-gallery a h3:after {
  position: static;
}

.video-gallery a img {
  width: 52px;
  margin-right: 10px;
}

.row.tabs-dynamic {
  background: #f1f1f1;
  padding: 20px;
  margin: 0px 0px 40px 0px;
}

.row.tabs-dynamic p {
  margin-bottom: 0;
}

.row.tabs-dynamic p a {
  color: #585757;
}

.row.tabs-dynamic select {
  width: 100%;
  border: 0px;
  background: transparent;
  padding: 8px 5px;
  border-bottom: 1px solid #b6b6b6;
}

.row.tabs-dynamic input {
  padding: 9px 5px;
  width: 100%;
  border: 0px;
  border-bottom: 1px solid #b6b6b6;
  background: transparent;
}

.count-val p {
  margin-bottom: 0px;
}

.gallery-inner {
  background: linear-gradient(88deg, #f1f1f1, #f1f1f1);
  margin-bottom: 30px;
  box-shadow: 7px 6px 12px -4px #ddd;
  /* position: relative; */
  overflow: hidden;
  transition: all 0.5s ease-in;
}

.gallery-blk a {
  color: inherit;
}

.gallery-content {
  padding: 20px 15px;
  position: relative;
  background: #fff;
  width: 96%;
  margin: -30px auto 10px auto;
}

.gallery-blk img {
  width: auto;
  max-width: 100%;
  height: 90%;
  margin: auto;
  display: block;
  position: relative;
  box-shadow: 0px 0px 19px 0px #00000054;
  border: 7px solid #ffffff4f;
}

.gallery-inner:hover .gallery-content {
  bottom: 0;
}

.gallery-blk {
  height: 230px;
  object-fit: contain;
  position: relative;
  background: #ddd;
  overflow: hidden;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub-gallerycount .gallery-blk img {
  height: 100%;
}

.sub-gallerycount .gallery-blk {
  height: 200px;
  margin-bottom: 20px;
}

.gallery-blk .gallery-title h3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.gallery-title h3 {
  font-size: 18px !important;
  color: #333 !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  padding: 0px;
  margin-bottom: 15px;

}

a.view-more {
  color: var(--black);
  font-size: 14px;
  cursor: pointer;
}

.gallery-title p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  line-height: 28px !important;
  font-size: 16px !important;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}

.gallery-date {
  display: flex;
}

.gallery-date h6 {
  color: #fff;
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 25px;
  background: #d1be56;
  padding: 5px 10px;
  border-radius: 5px;

}

.gall-num h5 {
  background: transparent;
  color: #000;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 25px;
  padding: 5px 10px;
  border-radius: 5px;

}

.gallery-title {
  font-size: 20px;
  line-height: 30px;

}

.sub-gallerycount img {
  height: 200px;
  width: 100%;
  object-fit: contain;
  object-position: center;
  background: #f1f1;
  box-shadow: 0px 0px 3px 1px #0000004a;
}

.sub-gallerycount {
  margin-bottom: 20px;
}

.news-head {
  display: flex;
  align-items: center;
}

.news-head h2 {
  padding-right: 20px;
  border-right: 1px solid #c5c5c5;
  margin-right: 20px;
  background: #e5e5e5;
  padding: 4px 10px;
}

.news-head h3 {
  font-weight: 400;
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 14px;
  color: #00599b;
  position: relative;
}
span.star {
  color: red;
}
.guideheading i {
  font-size: 6px;
}
.innersec.news-blk {
  margin-bottom: 20px;
  padding-right: 30px;
  padding-bottom: 30px;
  width: 97%;
  box-shadow: 9px 11px 5px 0px #dddddd3d;
  position: relative;
  overflow: hidden;
  padding: 20px;
  box-shadow: 0px 0px 12px 0px #e7e7e7;
  border: 4px double #b5b5b5;
  transition: 0.5s;
}
.mobileappimg img {
  margin-bottom: 20px;
  margin-top: 20px;
  width: 310px;
}
.innersec.news-blk:before {
  content: "";
  position: absolute;
  top: -40px;
  right: -8px;
  width: 100px;
  height: 100px;
  background: #ecf9ff;
  border-radius: 50%;
}

.attachment {
  background: #494b7d;
  text-align: right;
  position: absolute;
  right: 0;
  bottom: 0;
  color: #fff;
  padding: 5px;
}

.news-content p {
  margin-bottom: 6px;
  max-height: 160px;
  overflow-y: scroll;
}

.news-content p::-webkit-scrollbar {
  width: 0;
}

.innersec.news-blk:hover {
  box-shadow: rgb(0 0 0 / 5%) 0px 2px 1px, rgb(0 0 0 / 5%) 0px 4px 2px, rgb(0 0 0 / 5%) 0px 8px 4px, rgb(0 0 0 / 5%) 0px 16px 8px, rgb(0 0 0 / 5%) 0px 32px 16px;
}

.news-head h2 span {
  display: block;
  color: #153168;
  font-size: 18px;
  text-align: center;
}

.newsCount:nth-child(4n+1)>div {
  border-left: 3px solid #fff27e;
}

.newsCount:nth-child(4n+2)>div {
  border-left: 3px solid #ceb2ff;
}

.newsCount:nth-child(4n+3)>div {
  border-left: 3px solid #ff928f;
}

.newsCount:nth-child(4n+4)>div {
  border-left: 3px solid #ffb470;
}

.eventsnewsbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: #fff;
  box-shadow: 2px 0px 8px 0px #ccc;
  margin-bottom: 20px;
}

.newsboximg {
  width: 40%;
  margin-right: 10px;
}

.newsboxdesc {
  width: 58%;
}

.newspgh {
  height: 150px;
  overflow-y: scroll;
  padding-top: 15px;
  margin-top: 10px;
  padding-right: 10px;
}

.uniformimg {
  height: auto;
  margin-bottom: 20px;
}

.newsboximg img {
  border: 10px solid #fff;
  height: 190px;
  object-fit: contain;
  background: #f1f1f1;
  width: 100%;
}

.newsdate {
  display: flex;
  justify-content: space-between;
  padding: 8px 5px;
}

.newspgh p {
  font-size: 15px;
  line-height: 30px;
}

.newsdate a {
  background: #881525;
  border-radius: 5px 3px 6px 0px;
  color: #fff;
  padding: 2px 5px;
}

.newsdate span {
  font-size: 14px;
  line-height: 30px;
}

.event-logo {
  height: 320px !important;
}

i {
  position: relative;
  top: -2px;
}

.newspgh h5 {
  font-family: "Inter", sans-serif;
  color: #000000ad;
  line-height: 30px;
  font-size: 18px;
}

.newspgh::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.newspgh::-webkit-scrollbar-thumb {
  background-color: var(--maroon);
}

.newspgh::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #F5F5F5;
}

.gallery-blks img {
  height: 230px;
  width: 100%;
  object-fit: contain;
  object-position: center;
  background: #f1f1f1;
}

.toppers-inner {
  padding: 10px;
  min-height: 308px;
  margin-bottom: 20px;
  background: #42437024;
  border-radius: 3px;
  box-shadow: inset 0px 0px 13px 5px #3d3e6857;
}

.toppers-inner img {
  margin: auto;
  width: 150px;
  height: 140px;
  border-radius: 3px;
  margin-top: 20px;
  display: block;
  border: 6px solid #fff;
}

.toppers-inner h3 {
  color: #333 !important;
  text-align: center;
  font-size: 16px !important;
  line-height: 25px !important;
  margin: 0px;
  margin-top: 10px;
}

.toppers-inner p {
  text-align: center;
  margin-bottom: 0;
}

.toppers-inner h4 {
  font-size: 15px;
  text-align: center;
}

.toppers-inner h5 {
  margin: 0px;
  color: #ac2121;
  font-size: 15px;
  text-align: center;
  line-height: 25px;
}

.magnify-modal {
  position: fixed !important;
  z-index: 999999999 !important;
  padding-top: 10px !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  overflow: auto !important;
  background-color: rgba(0, 0, 0, 0.8509803922) !important
}

.magnify-header .magnify-toolbar {
  float: right;
  position: relative;
  z-index: 9
}

.magnify-stage {
  position: absolute;
  top: 40px;
  right: 10px;
  bottom: 40px;
  left: 10px;
  z-index: 1;
  background: #0000000a;
  overflow: hidden
}

.magnify-image {
  position: relative;
  display: inline-block;
  border: 5px solid #fff
}

.magnify-image.image-ready {
  max-width: 100%;
  max-height: 100%
}

.magnify-footer {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 40px;
  color: #fff;
  text-align: center
}

.magnify-footer .magnify-toolbar {
  display: inline-block
}

.magnify-button {
  display: inline-block;
  width: 40px;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  background: #055a91;
}

.magnify-button:hover {
  color: #fff;
  background-color: #e00000
}

.magnify-button-close:hover {
  background-color: #e00000
}

.magnify-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: #333
}

.magnify-loader::before {
  content: '';
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  -webkit-animation: magnifyLoading 1s infinite linear;
  animation: magnifyLoading 1s infinite linear
}

.magnify-loader::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  overflow: hidden
}

.tcblock {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.tc {
  width: 49%;
  margin-bottom: 30px;
}

.tcblock iframe {
  width: 49%;
}

.tc label {
  color: #000;
  width: 100%;
  margin-bottom: 0;
}

.tc label p {
  margin-bottom: 0px;
}

.tc .form-group {
  margin-bottom: 20px;
  width: 100%;
}

.form-control:focus {
  box-shadow: none;
  border: 1px solid #ccc;
}

a.viewdetailsbtn {
  color: #fff;
  font-size: 14px;
}

.viewdetailsbtn {
  padding: 10px 15px;
  text-decoration: none;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
  border: none;
  background: linear-gradient(131deg, #0e3151, #eb8406) !important;
  transition: all 0.2s ease-in;
}

.viewdetailsbtn:hover {
  background: linear-gradient(131deg, #eb8406, #0e3151) !important;
}

.mesdeskimg h3 {
  color: #e55e12;
  margin-bottom: 8px;
  font-size: 18px;
  margin-top: 30px;
  line-height: 30px;
}

.left-img {
  border: 10px solid #fff;
  box-shadow: 1px 5px 8px 0px #ccc;
  border-radius: 4px;
  margin-left: 20px;
  margin-bottom: 20px;
}

@-webkit-keyframes magnifyLoading {
  0% {
    -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
    transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
    -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
    transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}

@keyframes magnifyLoading {
  0% {
    -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
    transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
    -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
    transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}

.newsdate h2 {
  font-size: 14px;
  line-height: 30px;
  font-family: "Inter", sans-serif;
  margin-bottom: 0;
}

.newspgh h3 {
  font-family: "Inter", sans-serif;
  color: #333 !important;
  line-height: 30px !important;
  font-size: 18px !important;
}

.mesdeskimg h5 {
  font-size: 14px;
  color: #333;
}

.mainbox {
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  flex-wrap: wrap;
}

.mainbox p {
  width: 100%;
  background: #ffa39f1f;
  padding: 10px 8px;
  border-left: 5px solid #843b2e;
  margin: 0px 10px 15px 0px;
  box-shadow: 0px 3px 3px 1px #ccc;
  border-radius: 3px;
}

.innersec h3 {
  color: #051a2b;
  font-size: 20px;
  line-height: 30px;
}

ul.list li {
  width: calc(49% - 40px);
  position: relative;
  list-style: none;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  line-height: 34px;
  color: #4e4e4e;
  margin-right: 35px;
}

ul.list {
  display: flex;
  flex-wrap: wrap;
}

ul.list li:after {
  color: #c9b258;
  content: "\2726";
  font-size: 1.6em;
  left: -30px;
  position: absolute;
  top: 0;
}

.admissioninfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.admissionbox {
  width: calc(33% - 20px);
  text-align: center;
  background: #fff;
  padding: 10px 15px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0px 4px 9px 1px #0603034a;
  border: 1px dashed #055a91;
  position: relative;
  overflow: hidden;
}
button.removebtn {
  border: 0;
  background: #d47a0c;
  color: #fff;
  margin-top: 10px;
}
.admissionbox:before {
  content: "";
  position: absolute;
  top: 0;
  right: -40px;
  height: 100%;
  width: 100px;
  background: #e5eff3;
  border-radius: 50%;
}

.admissionbox img {
  width: 50px;
  float: left;
  margin-right: 20px;
  margin-top: 15px;
  margin-bottom: 25px;
}

.admissionbox p {
  font-size: 17px !IMPORTANT;
  color: #055a91 !important;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  margin-bottom: 0 !IMPORTANT;
}

.admissionbox a {
  font-weight: bold;
  color: #000;
  text-align: left;
  display: flex;
}

.formbox {
  flex-wrap: wrap;
  background: #fff;
  box-shadow: 0 30px 50px rgb(0 0 0 / 10%);
  display: flex;
  margin-bottom: 60px;
  border-radius: 4px;
}

.formboxright {
  width: 70%;
  background: #f1f1f1;
  padding: 50px 20px 50px 20px;
}

.addressimg i {
  color: #e78105;
}

.formboxleft {
  width: 30%;
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #144a7a;
}

.addressimg {
  float: left;
  width: 45px;
  height: 45px;
  background: #fff;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 10px;
}

.addressdesc a {
  color: #fff;
  font-size: 14px;
  line-height: 33px;
  font-family: 'Poppins', sans-serif;
  word-break: break-all;
}

.addressdesc {
  margin-bottom: 20px;
  clear: both;
}

.form-group {
  margin-bottom: 15px;
  width: 48%;
  margin-right: 17px;
}

.formdiv {
  display: flex;
  flex-wrap: wrap;
}

.form-group label {
  margin-bottom: 10px;
}

.formboxleft h5 {
  font-size: 30px;
  color: #fff;
  font-family: revert;
  margin-bottom: 30px;
  text-align: center;
  line-height: 40px;
}

input[type="submit"] {
  transition: all 0.3s ease-in;
  padding: 10px 30px;
  border: 0px solid #ccc;
  background: linear-gradient(45deg, #144a7a, #be973e);
  color: #fff;
  border-radius: 4px;
  margin-top: 10px;
}

.form-group.textarea {
  width: 100%;
}

.form-group.textarea textarea {
  resize: none;
}

.popup {
  padding-right: 0 !important;
}

.popup .carousel-control-prev-icon {
  background: url('https://webapi.entab.info/api/image/JRPSB/public/Images/lefticn.png') no-repeat !important;
}

.popup .carousel-control-next-icon {
  background: url('https://webapi.entab.info/api/image/JRPSB/public/Images/righticn.png') no-repeat !important;
}

.popup .carousel-control-next,
.popup .carousel-control-prev {
  width: auto;
}

.popup .carousel-control-next {
  right: -15px;
}

.popup .modal-dialog {
  min-width: 40%;
  transform: translateY(-100px);
}

.carousel-item .modal-header {
  background: #0a4685;
  padding: 13px 0 0
}

.popup .modal-dialog img {
  width: 100%;
  object-fit: contain;
}

.carousel-item .modal-body h1 {
  padding-bottom: 0;
  text-align: center;
  font-size: 17px;
  color: #000;
  overflow: hidden;
  font-weight: 600;
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
  line-height: 28px;
}

.carousel-item .modal-dialog .modal-header .btn-close {
  color: #fff;
  padding-right: 31px
}

.carousel-item .modalcard .card-body {
  padding: 10px
}

.carousel-item .modalcard .card-body p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 35px;
  text-align: center;
  padding-top: 10px;
  line-height: 26px;
}

.carousel-item .modalcard {
  border: none
}

.popup .modal-content {
  padding: 0 0 10px
}

.carousel-item button.btn-close:focus {
  box-shadow: none
}

.carousel-item a.btn.btn-primary.btn-sm {
  border-radius: 6px;
  box-shadow: -2px 14px 11px -9px #0000008a;
  transition: .5s;
  background: #081e32;
  border: #081e32;
  margin: 0 10px;
  color: #fff !important;
  padding: 10px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
}

.carousel-item a.btn.btn-primary.btn-sm:focus {
  box-shadow: none;
  border: 1px solid #081e32;
}

.carousel-item a.btn.btn-primary.btn-sm:hover {
  background: linear-gradient(0deg, #081e32, #f56c6c);
}

.carousel-item button.btn-close {
  position: absolute;
  z-index: 999;
  background-color: #081e32;
  right: 0;
}

.carousel-item .btn-close {
  background: url('https://webapi.entab.info/api/image/JRPSB/public/Images/closeicn.png') no-repeat;
  opacity: 1;
  padding: 10px;
  background-position: center;
  border-radius: 0;
}

.modal-bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.galleryimgbox img {
  height: 280px;
  width: 100%;
}

span.bounce1 img {
  width: 22px;
}

.OnlineR a {
  color: #fff;
}

a.parantlogo {
  padding: 2px 10px;
}

.OnlineR {
  position: fixed;
  right: 0;
  z-index: 99;
  text-align: center;
  color: #fff;
  top: 40%;
  font-family: Open Sans, sans-serif;
}

@keyframes bounce1 {
  0% {
    background-color: #081e32;
  }

  33% {
    background-color: #ecb30b;
  }

  66% {
    background-color: #081e32;
  }

  100% {
    background-color: #ecb30b;
  }
}

.bounce1 {
  border-radius: 25px 0px 0px 25px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  animation: bounce1 1s infinite;
  padding: 10px 12px;
}

.maintable thead th {
  border: 1px solid #ccc;
  background: #113f67;
  color: #fff;
}

.maintable tbody td {
  border: 1px solid #ccc;
}

.maintable tbody tr:nth-child(odd) {
  background: #f1f1f185;
}

.maintable tbody tr:nth-child(even) {
  background: #13457136;
}

.tablebg {
  background: #f1f1f18a;
  padding: 20px 10px;
  border: 1px solid #cccccc36;
}

.tablebg .maintable tbody tr:nth-child(odd) {
  background: #fff;
}

.tclist {
  width: 49%;
}

.tclist ul.list {
  display: block;
}

.tclist ul.list li {
  width: 100%;
}

.principalimgbox img {
  width: 135px;
  height: 135px;
  border-radius: 100%;
  border: 4px solid #eb8305;
}

.messagedescpara p {
  color: #fff;
  line-height: 27px;
}

.messagedescpara {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
  overflow: hidden;
}

.messagedescpara p span {
  color: #fff !important;
}

.messagedescpara p div p strong {
  color: #fff !important;
}

.homeeventboxbottom.eventbotm span:nth-child(1) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  width: 60%
}

.form-group {
  width: 48%;
  margin-right: 17px;
}

.formdiv {
  display: flex;
  flex-wrap: wrap;
}

.selectclass {
  width: 100% !important;
}

.error {
  margin-bottom: 10px;
  margin-top: 5px;
  color: red;
  font-size: 13px;
}

form.careerform-feilds>div {

  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 0;
}

form.careerform-feilds>div label {
  margin-top: 5px;
  margin-bottom: 5px;
}

form.careerform-feilds {


  background: #f1f1f1;
  padding: 30px;
  box-shadow: 0px 0px 8px 1px #ccccccc9;
  border-radius: 3px;
  flex-wrap: wrap;
}

form.careerform-feilds .submit input {
  margin: 0;
  margin-top: 20px;
  background: #071a2b;
  border: 0;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
}

.form-heading h6 {
  color: #000;
}

.qrcode img {
  width: 160px;
  margin: 20px auto 0 auto;
  display: block;
}

.qrcode {
  width: 100% !important;
}

.primarydiv {
  width: 100% !important
}

.campuslogo {
  position: fixed;
  right: 0px;
  bottom: 10px;
  z-index: 99;
}

.campuslogo img {
  width: 100px;
}

@media all and (min-width: 992px) {

  .navbar .dropdown-menu-end {
    right: 0;
    left: auto;
  }

  .navbar .nav-item .dropdown-menu {
    padding: 0;
    border-radius: 2px;
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    margin-top: 0;
  }

  .navbar .dropdown-menu.fade-down {
    top: 80%;
    transform: rotateX(-75deg);
    transform-origin: 0% 0%;
  }

  .navbar .dropdown-menu.fade-up {
    top: 180%;
  }

  .navbar .nav-item:hover .dropdown-menu {
    transition: .3s;
    opacity: 1;
    visibility: visible;
    top: 100%;
    transform: rotateX(0deg);
  }
}

@media(max-width: 1668px) {

  .button-ftr img {
    width: 80px;
  }

  .homeeventbox img {
    height: 157px;
  }

  .news-blk img {
    height: 110px;
  }


}

@media(max-width: 1600px) {
  img.img-fluid.phone-img {
    min-width: 106px;
    float: right;
    margin-top: 0;
  }
}

@media(max-width: 1400px) {
  img.img-fluid.phone-img {
    min-width: 60px;
  }

  .quick-link ul {
    /* height: auto; */
    display: flex;
  }

  .toopersecbg .stripClass {
    min-width: 110px;
  }
}

@media(max-width: 1254px) {
  .footer {
    background: #d47a0c;
  }

  .quick-link,
  .cont-dtl {
    margin-top: 20px;
  }

  .button-flex button {
    min-width: 137px;
  }

  .toopersecbg .stripClass .classtopper {
    padding: 14px;
  }

  .toopersecbg .stripClass {
    min-width: 70px;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 5px;
    padding-left: 5px;
  }

}

@media (max-width:1199px) {
  .maintitle h2 {
    font-size: 27px;
  }

  .aboutsec .title h2 {
    font-size: 30px;
  }

  img.img-fluid.phone-img {
    min-width: 60px;
    height: 177px;
    object-fit: contain;
  }





}

@media (max-width: 991px) {
  .form-group {
    width: 47%;
  }

  .formboxright {
    width: 100%;
  }

  .formboxleft {
    width: 100%;
  }

  .homeeventbox img {
    height: 320px;
  }

  .news-blk img {
    height: 230px;
  }

  .button-flex {
    justify-content: left;
    margin-bottom: 20px;
  }

  li.nav-item.public_dis {
    position: static;
  }

  .four-blk {
    flex-wrap: wrap;
  }

  .slider-text h1 {
    font-size: 28px;
    line-height: 42px;
  }

  .slider-text {
    width: 600px;
    max-width: 44%;
    padding: 11px;
    bottom: 60px;
    left: 60px;
  }

  nav.navbar.navbar-expand-lg button.navbar-toggler {
    position: absolute;
    background: #fff;
    top: -56px;
  }

  .button-flex button {
    min-width: fit-content;
  }

  .navbar-collapse {
    padding-top: 10px;
    padding-bottom: 10px;
    position: absolute;
    top: 0;
    background: #005c9d;
    width: 100%;
    z-index: 9;
    left: 0;
    padding-left: 20px;
  }

  nav.navbar.navbar-expand-lg {
    width: 100%;
    padding-left: 0;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
  }

  .messagedesc {
    padding: 6px 41px 100px;
  }

  .messagebox img.img-fluid.top-quote {
    position: absolute;
    width: 50px;
    left: 23px;
    top: 0;
  }

  .messagebox img.img-fluid.bottom-quote {
    width: 50px;
  }

  .messagebox {
    padding: 0;
    background-size: auto 113%;
    background-position: center;
  }

  .messagedesc {
    padding: 40px 40px 100px;
  }
}

@media(max-width: 767px) {
  ul.list li {
    width: 100%;
  }

  .form-group {
    width: 100%;
  }

  .tc {
    width: 100%;
  }

  .left-img {
    margin-left: 0;
  }

  .tcblock iframe {
    width: 100%;
  }

  .slider-text {
    display: none;
  }

  .d-flex.mb-app, .map {
    width: 100%;
    display: flex
;
    align-items: center;
    justify-content: space-between;
}
img.img-fluid.phone-img {
  min-width:100px;
  height:220px;
}
  .footer-wrap {
    flex-wrap: wrap;
  }

  .aboutsec .title h2 {
    font-size: 2.5rem;
  }

  .messagebox {
    background: var(--primary-color);
    padding: 10px 0px 10px 0px;
  }

  .principalimgbox p,
  .principalimgbox p span {
    color: #fff;
  }

  .messagedesc {
    padding: 40px 40px 70px;
  }

  .button-flex {
    justify-content: flex-start;
    margin-bottom: 20px;
  }
}

@media(max-width: 567px) {
  .newsboxdesc {
    width: 100%;
    margin-left: 10px;
  }

  .newsboximg {
    width: 100%;
  }

  .innersec h1 {
    font-size: 30px;
    padding-left: 50px;
  }

  .innersec h1:after {
    width: 40px;
  }

  img.img-fluid.phone-img {
    width: 80px;
    float: none;
  }
  .quick-link ul {
    height: auto;
    display: flex;
}

.contact {
  display: block;
}


}

@media(max-width: 400px) {

  .homeeventbox img {
    height: 210px;
  }

  
  nav.navbar.navbar-expand-lg button.navbar-toggler {
    top: -50px;
  }

  .news-blk img {
    height: 150px;
  }

  .button-flex button {
    min-width: 100px;
  }
}